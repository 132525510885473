import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Masthead from '../components/masthead/masthead'
import Tour from '../components/tour'
import TourItem from '../components/tourItem/tourItem'
import Cta from '../components/cta/cta'

const Welcome = () => (
  <Layout modifiers={[`white`]}>
    <SEO
      title="Find your financial freedom"
      keywords={[`personal finance`, `fintech`, `ASEAN`]}
    />
    <Masthead type={`balloon`}
      headline={`Hello 👋\nWe’re pilot`}
      byline=""
      modifiers={[`white`, `centered`]} />
    <Tour>
      <TourItem
        headline={`We’re helping people from 🇮🇩 and 🇵🇭 find their  financial freedom 💸`}
        modifier="white"
      />
      <TourItem
        headline={`We know how hard it is living in 🇭🇰 or 🇸🇬`}
        modifier="white"
      />
      <TourItem
        headline={`So we got together a friendly 🙋 community 🙆‍♂️ of people. We call them pilots`}
        modifier="white"
      />
      <TourItem
        headline={`Together, we're finding new ways to help you spend 🤑, save 💰 and borrow 🏦`}
        modifier="white"
      />
    </Tour>
    <Cta
      type="typeform"
      modifiers={[`centered`]}
      headline={`And we'd like you to join us`}
      byline={`Want to save more money or start investing? Wondering how your finances might affect your family?\nFind out how pilot can help you`}
      linkTo="https://joinpilot.typeform.com/to/qvLkGC"
      linkText={`Let's go!`}
    />
  </Layout>
)

export default Welcome
